import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

const useDarkMode = () => {
  const prefersDarkMediaQuery = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  const getInitialDarkMode = () => {
    const storedState = localStorage.getItem("darkMode");
    return storedState ? JSON.parse(storedState) : prefersDarkMediaQuery;
  };

  const [darkMode, setDarkMode] = useState(getInitialDarkMode);

  useEffect(() => {
    const storedState = localStorage.getItem("darkMode");
    setDarkMode(
      storedState !== null ? JSON.parse(storedState) : prefersDarkMediaQuery,
    );
  }, [prefersDarkMediaQuery]);

  const toggleDarkMode = () => {
    setDarkMode((prevState) => {
      const newDarkMode = !prevState;
      localStorage.setItem("darkMode", JSON.stringify(newDarkMode));
      return newDarkMode;
    });
  };

  return [darkMode, toggleDarkMode];
};

export default useDarkMode;
