import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CardTextual = ({
  avatarBackgroundColor,
  data,
  hasIcon,
  iconFill,
  ...props
}) => (
  <Card
    {...props}
    sx={{
      borderRadius: 2,
      height: "100%",
    }}
    variant="outlined"
  >
    <CardHeader
      {...(hasIcon && {
        avatar: (
          <Avatar
            sx={{
              bgcolor: avatarBackgroundColor,
              height: { md: 48, sm: 44, xs: 40 },
              width: { md: 48, sm: 44, xs: 40 },
            }}
          >
            <data.icon
              sx={{
                fill: iconFill,
                fontSize: { md: 24, sm: 22, xs: 20 },
              }}
            />
          </Avatar>
        ),
      })}
      sx={{ pb: 0 }}
      title={data.title}
      titleTypographyProps={{
        variant: "h6",
      }}
    />
    <CardContent>
      <Typography align="justify" color="text.secondary">
        {data.content}
      </Typography>
    </CardContent>
  </Card>
);

CardTextual.propTypes = {
  avatarBackgroundColor: PropTypes.string,
  data: PropTypes.object.isRequired,
  hasIcon: PropTypes.bool.isRequired,
  iconFill: PropTypes.string,
};

export default CardTextual;
