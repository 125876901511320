import React from "react";
import PropTypes from "prop-types";
import { Button, SvgIcon } from "@mui/material";
import { UK } from "../../assets";

const LanguageButton = ({ language, onClick }) => (
  <Button
    aria-label={`Toggle language change to ${language}`}
    color="inherit"
    disabled
    size="small"
    startIcon={<SvgIcon component={UK} inheritViewBox variant="filter" />}
    onClick={onClick}
    sx={{
      borderColor: "divider",
      borderRadius: 2,
      borderStyle: "solid",
      borderWidth: 1,
    }}
  >
    {language}
  </Button>
);

LanguageButton.propTypes = {
  language: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LanguageButton;
