import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  styled,
} from "@mui/material";
import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { uid } from "react-uid";

import { publicRoutes } from "../../configs/router/publicRoutes";
import { AegisIcon } from "../../assets";
import { useLocation, useMatch } from "react-router";
import { useTheme } from "@emotion/react";

const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  fontSize: 18,
  textDecoration: "none",
  cursor: "pointer",
  whiteSpace: "nowrap",

  "&.active, &:hover": {
    color: theme.palette.text.primary,
    fontWeight: "bold",

    "&::after": {
      width: "100%",
    },
  },

  "&::after": {
    content: '""',
    display: "block",
    height: 3,
    width: 0,
    background: theme.palette.primary.main,
    transition: "width .25s",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.subtitle1,
  borderRadius: 4,
  color: theme.palette.text.secondary,
  fontSize: 18,

  "&.active, &:hover": {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  maxWidth: theme.breakpoints.values["lg"],
  marginInline: "auto",
}));

const ContactLink = ({ id, path }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const primaryColor = isDarkMode
    ? theme.palette.primary.main
    : theme.palette.primary.light;
  const secondaryColor = isDarkMode
    ? theme.palette.secondary.main
    : theme.palette.secondary.light;

  return (
    <Link sx={{ textDecoration: "none" }} href={path}>
      <motion.div
        animate={{
          background: [
            `linear-gradient(to right, ${primaryColor} -200%, ${secondaryColor} -100%, ${primaryColor} 0%, ${secondaryColor} 100%)`,
            `linear-gradient(to right, ${primaryColor} -100%, ${secondaryColor} 0%, ${primaryColor} 100%, ${secondaryColor} 200%)`,
            `linear-gradient(to right, ${primaryColor} 0%, ${secondaryColor} 100%, ${primaryColor} 200%, ${secondaryColor} 300%)`,
          ],
        }}
        whileHover={{
          filter: "brightness(1.2) contrast(1.2)",
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "linear",
        }}
        style={{ borderRadius: 4 }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "transparent",
            color: "text.primary",
            fontWeight: "bold",

            "&:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          {id}
        </Button>
      </motion.div>
    </Link>
  );
};

ContactLink.propTypes = {
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

const PopupLink = ({ route }) => {
  const location = useLocation();
  const match = useMatch(route.path);

  const popupState = usePopupState({
    popupId: uid(route),
    variant: "popover",
  });

  const isActiveRoute = (route, match, location) =>
    match
      ? match.pattern.path === route.path
      : location.pathname.includes(route.path);

  return (
    <>
      <StyledLink
        {...bindTrigger(popupState)}
        component="div"
        className={isActiveRoute(route, match, location) ? "active" : ""}
      >
        <span>{route.id}</span>
        {popupState.isOpen ? (
          <ExpandLessIcon sx={{ verticalAlign: "middle" }} />
        ) : (
          <ExpandMoreIcon sx={{ verticalAlign: "middle" }} />
        )}
      </StyledLink>
      <Menu
        aria-labelledby="demo-positioned-button"
        id="demo-positioned-menu"
        keepMounted
        {...bindMenu(popupState)}
        slotProps={{
          paper: {
            sx: {
              borderBottomColor: "primary.main",
              borderBottomStyle: "solid",
              borderBottomWidth: 3,
              borderRadius: "0px 0px 8px 8px",
              padding: 2,
            },
          },
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClick={popupState.close}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
      >
        {(route.patterns || route.children).map(({ id, path }) => (
          <StyledMenuItem
            href={`${route.path}/${path}`}
            key={uid({ id, path })}
            onClick={popupState.close}
          >
            {id}
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
};

PopupLink.propTypes = {
  route: PropTypes.shape({
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.array,
    patterns: PropTypes.array,
  }).isRequired,
};

const RegularLink = ({ id, path }) => <StyledLink href={path}>{id}</StyledLink>;

RegularLink.propTypes = {
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

const NavBarLink = ({ route }) =>
  route.children || route.patterns ? (
    <PopupLink route={route} />
  ) : route.path === "contact" ? (
    <ContactLink id={route.id} path={route.path} />
  ) : (
    <RegularLink id={route.id} path={route.path} />
  );

NavBarLink.propTypes = {
  route: PropTypes.shape({
    path: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.array,
    patterns: PropTypes.array,
  }).isRequired,
};

const NavBar = ({ drawerOpen, onToggleDrawer }) => (
  <StyledToolbar
    component="nav"
    sx={{
      justifyContent: "space-between",
      gap: { sm: 2, md: 4 },
    }}
  >
    <IconButton
      component={Link}
      aria-label="go to home"
      href="/"
      id="logo-header"
    >
      <AegisIcon sx={{ fontSize: 48 }} />
    </IconButton>
    <div>
      <Stack
        direction="row"
        spacing={{ sm: 2, md: 4 }}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        {publicRoutes
          .filter((route) => route.id != null)
          .map((route) => (
            <NavBarLink key={uid(route)} route={route} />
          ))}
      </Stack>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={onToggleDrawer}
        sx={{
          display: { sm: "none" },
        }}
      >
        {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
      </IconButton>
    </div>
  </StyledToolbar>
);

NavBar.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  onToggleDrawer: PropTypes.func.isRequired,
};

export default NavBar;
