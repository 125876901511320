import React from "react";
import { createBrowserRouter } from "react-router-dom";

import { publicRoutes, notFoundRoute } from "./publicRoutes";
import App from "../../App";

const routerConfig = createBrowserRouter([
  {
    children: [...publicRoutes, notFoundRoute],
    element: <App />,
    path: "/",
  },
]);

export default routerConfig;
