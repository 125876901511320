import React from "react";
import {
  AboutView,
  BlogView,
  CareersView,
  ContactView,
  HomeView,
  IconView,
  LegalView,
  // ServiceDetailsView,
  ServicesView,
} from "../../views";
import { NotFound } from "../../assets";
import {
  privacyPolicy,
  termsAndConditions,
  compliance,
  penetrationTesting,
  todo,
} from "../../models";

const homeLoader = async () => {
  const services = [compliance, penetrationTesting, ...todo].sort((a, b) =>
    a.title.localeCompare(b.title),
  );

  return services.map(({ icon, title, subheader, href }) => ({
    icon,
    title,
    subheader,
    href,
  }));
};

const serviceLoader = async ({ params }) => {
  const services = [compliance, penetrationTesting, ...todo];
  const filteredData = services.find((x) => x.id === params.viewId);

  if (filteredData) return filteredData;
  else throw new Response("Not Found", { status: 404 });
};

// const serviceDetailsLoader = async ({ params }) => {
//   const service = services.find((x) => x.id === params.viewId);
//   const serviceDetails = service?.children.find(
//     (child) => child.id === params.serviceName
//   );
//   if (serviceDetails) return serviceDetails;
//   else throw new Response("Not Found", { status: 404 });
// };

const legalLoader = async ({ params }) => {
  if (["privacy-policy", "terms-and-conditions"].includes(params.viewId))
    return params.viewId === "privacy-policy"
      ? privacyPolicy
      : termsAndConditions;
  else throw new Response("Not Found", { status: 404 });
};

const notFoundRoute = {
  element: (
    <IconView
      icon={NotFound}
      message="It seems that the page you were trying to reach does not exist anymore, or maybe it has just moved."
      title="Page Not Found"
    />
  ),
  path: "*",
};

const publicRoutes = [
  {
    element: <HomeView />,
    index: true,
    id: "Home",
    path: "/",
    loader: homeLoader,
  },
  {
    element: <AboutView />,
    id: "About",
    path: "about",
  },
  {
    id: "Services",
    children: [
      {
        // element: <ServiceDetailsView />,
        element: <ServicesView />,
        loader: serviceLoader,
        errorElement: notFoundRoute.element,
        path: ":viewId",
      },
    ],
    patterns: [
      { id: "Compliance", path: "compliance" },
      { id: "Penetration Testing", path: "penetration-testing" },
    ],
    path: "services",
  },
  {
    children: [
      {
        element: <BlogView />,
        id: "Blog",
        path: "blog",
      },
      {
        element: <CareersView />,
        id: "Careers",
        path: "careers",
      },
    ],
    id: "Resources",
    path: "resources",
  },
  {
    element: <ContactView />,
    id: "Contact",
    path: "contact",
  },
  {
    children: [
      {
        element: <LegalView />,
        loader: legalLoader,
        errorElement: notFoundRoute.element,
        path: ":viewId",
      },
    ],
    patterns: [
      { id: "Privacy Policy", path: "privacy-policy" },
      { id: "Terms and Conditions", path: "terms-and-conditions" },
    ],
    path: "legal",
  },
];

export { publicRoutes, notFoundRoute };
