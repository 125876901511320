import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";
import { useCountUp } from "react-countup";
import Slider from "react-slick";
import { uid } from "react-uid";
import VisibilitySensor from "react-visibility-sensor";

import {
  colors,
  // HeroLanding,
  HeroLandingLg,
  HeroLandingMd,
  HeroLandingSm,
  HeroLandingXl,
} from "../../assets";
import {
  CardBlog,
  CardIcon,
  CardTestimonial,
  FormCallback,
  Hero,
} from "../../components";
import { blogposts, statistics, testimonials } from "../../models";
import { ReactTyped } from "react-typed";
import { useLoaderData, useNavigation, useOutletContext } from "react-router";

const HeroSection = () => (
  <Hero
    CTA={{ href: "/contact", text: "Talk to Sales" }}
    bgImages={{
      lg: HeroLandingXl,
      md: HeroLandingLg,
      sm: HeroLandingMd,
      xl: HeroLandingXl,
      xs: HeroLandingSm,
    }}
  >
    <Typography
      align="center"
      color="#FFFFFFFF"
      gutterBottom
      sx={{ fontWeight: "bold" }}
      variant="h2"
    >
      <ReactTyped
        strings={[
          "Your Trusted Cyber Security Partner",
          'Your Trusted Cyber Security <span style="font-weight: bolder; text-decoration: underline; text-transform: uppercase;">Partner</span>',
        ]}
        startDelay={512}
        typeSpeed={64}
        smartBackspace
        backSpeed={64}
        backDelay={512}
        onComplete={(typed) => typed.cursor.remove()}
      />
    </Typography>
    <Typography
      align="center"
      color="#FFFFFFB3"
      sx={{ display: "block", fontWeight: "bolder" }}
      variant="h3"
    >
      Committed to Safeguarding Your Business Against Cyber Attacks
    </Typography>
  </Hero>
);

const StatisticsSection = ({ statistics }) => (
  <>
    <Typography
      align="center"
      gutterBottom
      sx={{ fontWeight: 550, textTransform: "uppercase" }}
      variant="colorPrimary"
    >
      Statistics
    </Typography>
    <Typography align="center" gutterBottom variant="h4">
      Attack is the Secret of Defense
    </Typography>
    <Typography align="center" color="text.secondary" paragraph variant="h6">
      Work with our experts to review the cyber-resilience of your organisation
      with the aim of reinforcing it to counter real-world attacks.
    </Typography>
    <Stack
      direction={{ sm: "row", xs: "column" }}
      divider={<Divider flexItem orientation="vertical" />}
      spacing={2}
    >
      {statistics.map((item, index) => {
        const countUpRef = React.useRef(null);
        const { start } = useCountUp({
          decimals: index === 0 ? 2 : 0,
          duration: 2,
          end: item.title,
          prefix: item.prefix,
          redraw: true,
          ref: countUpRef,
          start: 0,
          suffix: item.suffix,
        });

        return (
          <Box key={uid(item)}>
            <VisibilitySensor delayedCall onChange={start}>
              <Typography
                align="center"
                color="text.primary"
                gutterBottom
                ref={countUpRef}
                sx={{ fontWeight: "bold" }}
                variant="h3"
              />
            </VisibilitySensor>
            <Typography align="center" color="text.secondary" gutterBottom>
              {item.content}
            </Typography>
            <Typography
              align="center"
              sx={{ fontStyle: "italic", fontWeight: 550 }}
              variant="colorPrimary"
            >
              {item.source}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  </>
);

StatisticsSection.propTypes = {
  statistics: PropTypes.array.isRequired,
};

const ServicesSection = ({ loadedData, theme }) => (
  <>
    <Typography
      align="center"
      gutterBottom
      sx={{ fontWeight: 550, textTransform: "uppercase" }}
      variant="colorPrimary"
    >
      Services
    </Typography>
    <Typography align="center" gutterBottom variant="h4">
      We Safeguard Your Organisation
    </Typography>
    <Typography align="center" color="text.secondary" paragraph variant="h6">
      The bespoke cyber security services that exceed both your needs and
      expectations.
    </Typography>
    <Grid
      component={motion.div}
      container
      initial={{ y: 64 }}
      spacing={2}
      transition={{ bounce: 0.25, duration: 2, type: "spring" }}
      viewport={{ amount: "some", once: false }}
      whileInView={{ y: 0 }}
    >
      {loadedData.map((data, index) => (
        <Grid item key={uid(data)} md={4} sm={6} xs={12}>
          <CardIcon
            avatarBackgroundColor={
              theme.palette.mode === "dark"
                ? colors.dark[index].background
                : colors.light[index].background
            }
            cardActionAreaProps={{
              href:
                data.href === "/contact"
                  ? `/contact?msg="${data.title}"`
                  : data.href,
            }}
            component={motion.div}
            data={data}
            iconFill={
              theme.palette.mode === "dark"
                ? colors.dark[index].foreground
                : colors.light[index].foreground
            }
            transition={{ bounce: 0.25, duration: 0.5, type: "spring" }}
            variant="outlined"
            whileHover={{ scale: 1.05 }}
          />
        </Grid>
      ))}
    </Grid>
  </>
);

ServicesSection.propTypes = {
  loadedData: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType.isRequired,
      title: PropTypes.string.isRequired,
      subheader: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ).isRequired,
  theme: PropTypes.object.isRequired,
};

const AboutSection = () => (
  <Grid alignItems="flex-start" container spacing={2}>
    <Grid item md={6} xs={12}>
      <Typography sx={{ fontWeight: "bold" }} variant="h4">
        We secure your business so that you can spend your time, energy and
        resources on what you do best - building and growing your business.
      </Typography>
    </Grid>
    <Grid item md={6} xs={12}>
      <div>
        <Typography
          align="justify"
          className="text-secondary-on-primary-background"
          paragraph
        >
          The cyber landscape continues to evolve. With digital transformation
          comes threats of a more advanced level and scale than ever before. Our
          team has{" "}
          <Link
            sx={{
              color: "text.primary",
              textDecoration: "underline",
            }}
            href="/about#strengths"
          >
            years of experience
          </Link>{" "}
          in helping businesses navigate this rapidly changing environment.
        </Typography>
        <Typography
          align="justify"
          className="text-secondary-on-primary-background"
          paragraph
        >
          We will work with you to arm your organisation with the knowledge to
          evade sophisticated modern-day threats and keep your business safe.
        </Typography>
      </div>
      <Button
        disableElevation
        endIcon={<ArrowRightAltIcon />}
        href="/about"
        size="large"
        sx={{
          bgcolor: "background.default",
          borderRadius: 2,
          color: "text.primary",
        }}
        variant="contained"
      >
        About Aegis Cyber
      </Button>
    </Grid>
  </Grid>
);

const TestimonialsSection = ({ testimonials }) => (
  <>
    <Typography
      align="center"
      gutterBottom
      sx={{ fontWeight: 550, textTransform: "uppercase" }}
      variant="colorPrimary"
    >
      Testimonials
    </Typography>
    <Typography align="center" gutterBottom variant="h4">
      Trusted by the World's Most Innovative Businesses - Big and Small
    </Typography>
    <Typography align="center" color="text.secondary" paragraph variant="h6">
      Companies from across the globe have had fantastic experiences using our
      services.
      <br />
      Here is what they have to say.
    </Typography>
    <Grid
      component={motion.div}
      container
      initial={{ y: 64 }}
      spacing={2}
      transition={{ bounce: 0.25, duration: 2, type: "spring" }}
      viewport={{ amount: "some", once: false }}
      whileInView={{ y: 0 }}
    >
      {testimonials.map((data, index) => (
        <Grid item key={uid(data)} md={4} sm={6} xs={12}>
          <CardTestimonial data={data} index={index} />
        </Grid>
      ))}
    </Grid>
  </>
);

TestimonialsSection.propTypes = {
  testimonials: PropTypes.array.isRequired,
};

const BlogSection = ({ sliderSettings }) => (
  <>
    <Typography
      align="center"
      gutterBottom
      sx={{ fontWeight: 550, textTransform: "uppercase" }}
      variant="colorPrimary"
    >
      Blog
    </Typography>
    <Typography align="center" gutterBottom variant="h4">
      Latest Security News and Articles
    </Typography>
    <Typography align="center" color="text.secondary" paragraph variant="h6">
      Read the latest blog posts from our expert analysts, and news about Aegis
      Cyber.
    </Typography>
    <Box sx={{ textAlign: "center" }}>
      <Button
        disableElevation
        endIcon={<ArrowRightAltIcon />}
        href="/resources/blog"
        sx={{
          borderRadius: 2,
        }}
        variant="contained"
      >
        See all
      </Button>
    </Box>
    <Box sx={{ px: 2 }}>
      <Slider {...sliderSettings}>
        {blogposts.map((item) => (
          <Box key={uid(item)} sx={{ padding: 2 }}>
            <CardBlog
              component={motion.div}
              data={item}
              key={uid(item)}
              transition={{ bounce: 0.25, duration: 0.5, type: "spring" }}
              whileHover={{ scale: 1.05 }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  </>
);

BlogSection.propTypes = {
  sliderSettings: PropTypes.object.isRequired,
};

const ContactSection = ({ theme }) => (
  <Paper
    elevation={theme.palette.mode === "dark" ? 2 : 4}
    sx={{
      borderRadius: 2,
      padding: { md: 4, xs: 2 },
    }}
    variant="elevation"
  >
    <Typography
      align="center"
      gutterBottom
      sx={{
        fontWeight: 550,
        textTransform: "uppercase",
      }}
      variant="colorPrimary"
    >
      Contact Us
    </Typography>
    <Typography align="center" gutterBottom variant="h4">
      Request a Callback
    </Typography>
    <Typography align="center" color="text.secondary" paragraph variant="h6">
      We are on standby to assist you, just complete the form below and we will
      contact you shortly.
    </Typography>
    <FormCallback />
  </Paper>
);

ContactSection.propTypes = {
  theme: PropTypes.object.isRequired,
};

const HomeView = () => {
  const theme = useTheme();
  const outletContext = useOutletContext();
  const loadedData = useLoaderData();
  const { state = "loading" } = useNavigation();

  const sliderSettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 3,
    speed: 500,
  };

  if (state === "loading") return <LoadingSpinner sx={outletContext.sx} />;

  return (
    <>
      <HeroSection />
      <div className="outer">
        <div className="inner">
          <StatisticsSection statistics={statistics} />
        </div>
      </div>
      <div className="outer">
        <div className="inner">
          <ServicesSection loadedData={loadedData} theme={theme} />
        </div>
      </div>
      <div className="outer background-primary">
        <div className="inner">
          <AboutSection />
        </div>
      </div>
      <div className="outer">
        <div className="inner">
          <TestimonialsSection testimonials={testimonials} />
        </div>
        <div className="inner">
          <BlogSection sliderSettings={sliderSettings} />
        </div>
      </div>
      <div className="background-primary outer">
        <div className="inner">
          <ContactSection theme={theme} />
        </div>
      </div>
    </>
  );
};

export default HomeView;
