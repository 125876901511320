export default {
  notice: "Privacy Policy",
  lastEdit: "25 Feb, 2022",
  background: `
**Please read this Privacy Policy carefully and ensure that you it before using Our Site.**

Aegis Cyber Limited understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website, [](/ "Our Site") ("Our Site") and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.

The following document[s] also apply to your use of Our Site:

- Our [Terms and Conditions](/legal/terms-and-conditions).
  `,
  questions: `
# 1. What does this policy cover?

This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.

# 2. What is personal data?

Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the "GDPR") and the Data Protection Act 2018 (collectively, "the Data Protection Legislation") as 'any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier'. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.

# 3. What are my rights?

Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:

1. The right to be informed about our collection and use of your personal data. This Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any questions.
2. The right to access the personal data we hold about you. Part 13 will tell you how to do this.
3. The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us to find out more.
4. The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we hold. Please contact us to find out more.
5. The right to restrict (i.e. prevent) the processing of your personal data.
6. The right to object to us using your personal data for a particular purpose or purposes.
7. The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for using your personal data, you are free to withdraw that consent at any time.
8. The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.
9. Rights relating to automated decision-making and profiling. We do not use your personal data in this way.

For more information about our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in Part 15.

It is important that your personal data is kept accurate and up-to-date. If any of the personal data we hold about you changes, please keep us informed as long as we have that data.

Further information about your rights can also be obtained from the Information Commissioner's Office or your local Citizens Advice Bureau. If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner's Office. We would welcome the opportunity to resolve your concerns ourselves, however, so please contact us first.

# 4. What data do you collect we and how?

The personal information we collect is determined by your relationship with Aegis Cyber. In this way, we strive to never collect more personal information than is necessary to provide you with our products, services, and industry information.

# 5. How do you use my personal data?

We may use your information for a number of purposes which includes: processing orders and managing and administering your account; delivering any services, products or information requested by you; responding to complaints or enquiries; administering debt recoveries and verifying your identity when required.

We may also undertake market and product analysis based on your use of our services and products and contact you with information about new developments, products, services, training courses, webinars and special offers by post, telephone and if you have consented accordingly by email (subject to any preferences expressed by you).

If you have consented to receive details of services, events and training you can contact us at any time to have your details removed from lists used by us for any or all of those purposes, to update your information or to otherwise tell us how you would like to receive information about our products and services - the choice is yours.

You can unsubscribe from the newsletter and/or our contact lists at any time.

To update your marketing preferences or to unsubscribe, please click the unsubscribe function in any communication you receive from us or email [support@aegiscyber.co.uk](mailto:support@aegiscyber.co.uk) and quote your name and address in the body of the email and tell us what you want us to do (e.g. 'opt-out Email' or if you have previously objected to receiving information by post for example, but would now like to change your mind and receive information then just say, 'opt-in post' in the subject header of your email).

# 6. How long will you keep my personal data?

To make sure we meet our legal data protection and privacy obligations, we only hold on to your information for as long as we actually need it for the purposes we acquired it for in the first place.

In most cases, this means we will keep your information for as long as you continue to use our services, and for a reasonable period of time afterwards if you stop doing so, to see if we can persuade you to use our services again.

After that, we will delete it other than where we lawfully can keep any data for audit or legal reasons.

We shall keep data on our prospect database for not longer than 24 months from the date of last contact with such prospect subject to an individual's right to unsubscribe or be forgotten at any time.

# 7. How and where do you store or transfer my personal data?

We share your data within the group of companies of which we are a part. Where this involves the transfer of personal data outside the EEA, our group ensures that personal data is protected by requiring all companies within the group to follow the same rules with respect to personal data usage. These are known as “binding corporate rules”. More information on binding corporate rules is available from the European Commission.

# 8. Do you share my personal data?

We will not share any of your personal data with any third parties for any purposes, subject to the following exceptions.

If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a third party. Any new owner of our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.

In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.

# 9. How can I control my personal data?

In addition to your rights under the Data Protection Legislation, when you submit personal data via Our Site, you may be given options to restrict our use of your personal data. In particular, we aim to give you strong controls on our use of your data for direct marketing purposes (including the ability to opt-out of receiving emails from us which you may do by unsubscribing using the links provided in our emails).

You may also wish to sign up to one or more of the preference services operating in the UK: The Telephone Preference Service (“the TPS”), the Corporate Telephone Preference Service (“the CTPS”), and the Mailing Preference Service (“the MPS”). These may help to prevent you receiving unsolicited marketing. Please note, however, that these services will not prevent you from receiving marketing communications that you have consented to receiving.

# 10. Can I withhold information?

You may access certain areas of Our Site without providing any personal data at all. However, to use all features and functions available on Our Site you may be required to submit or allow for the collection of certain data.

You may restrict our use of Cookies.

# 11. How can I access my personal data?

If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a "subject access request".

All subject access requests should be made in writing and sent to the email or postal addresses.

There is not normally any charge for a subject access request. If your request is 'manifestly unfounded or excessive' (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.

We will respond to your subject access request within one month and, in any case, not more than one month of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of our progress.

# 12. How do use cookies?

We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.

# 13. How do I contact you?

To contact us about anything to do with your personal data and data protection, including to make a subject access request, please email us at [support@aegiscyber.co.uk](mailto:support@aegiscyber.co.uk).

# 14. Changes to this Privacy Policy.

We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.

Any changes will be immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your first use of Our Site following the alterations. We recommend that you check this page regularly to keep up-to-date.
`,
};
