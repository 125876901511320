import { nopeResolver } from "@hookform/resolvers/nope";
import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import Nope from "nope-validator";
import { useSnackbar } from "notistack";
import React from "react";
import ReactGA from "react-ga4";
import { Controller, useForm } from "react-hook-form";

const FormCallback = () => {
  const { register, control, handleSubmit, formState } = useForm({
    defaultValues: {
      "form-name": "callback",
    },
    mode: "onChange",
    resolver: nopeResolver(
      Nope.object().shape({
        email: Nope.string().email().required(),
        name: Nope.string().required(),
        organisation: Nope.string().required(),
        tel: Nope.string().required(),
      }),
    ),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formData) => {
    ReactGA.event({
      action: "submit",
      category: "form",
      label: "callback",
    });

    // TODO: Required to bypass the SW cache for Netlify form compatibility
    fetch("/?t=" + Math.floor(Date.now() / 1000), {
      body: new URLSearchParams(formData).toString(),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
    })
      .then(() =>
        enqueueSnackbar("Form successfully submitted!", {
          variant: "success",
        }),
      )
      .catch(() =>
        enqueueSnackbar(
          "Something went wrong and your message could not be sent.",
          {
            variant: "error",
          },
        ),
      );
  };

  return (
    <Grid
      component="form"
      container
      justifyContent="center"
      name="callback"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      spacing={{ md: 2, xs: 1 }}
    >
      <Grid item md={6} xs={12}>
        <TextField
          {...register("name")}
          error={Boolean(formState.errors.name)}
          fullWidth
          helperText={
            Boolean(formState.errors.name) && formState.errors.name.message
          }
          id="name"
          label="Full name"
          name="name"
          required
          type="text"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          {...register("organisation")}
          autoComplete="organization"
          error={Boolean(formState.errors.organisation)}
          fullWidth
          helperText={
            Boolean(formState.errors.organisation) &&
            formState.errors.organisation.message
          }
          id="organisation"
          label="Company name"
          name="organisation"
          required
          type="text"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          {...register("email")}
          autoComplete="email"
          error={Boolean(formState.errors.email)}
          fullWidth
          helperText={
            Boolean(formState.errors.email) && formState.errors.email.message
          }
          id="email"
          label="Email address"
          name="email"
          required
          type="email"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          {...register("tel")}
          autoComplete="tel"
          error={Boolean(formState.errors.tel)}
          fullWidth
          helperText={
            Boolean(formState.errors.tel) && formState.errors.tel.message
          }
          id="tel"
          label="Phone number"
          name="tel"
          required
          type="tel"
        />
      </Grid>
      <Grid item textAlign="center" xs={12}>
        <FormControlLabel
          control={
            <Controller
              control={control}
              defaultValue={false}
              name="isSubscriber"
              render={({ field }) => <Checkbox {...field} />}
            />
          }
          label="Keep me updated with the latest security news and research."
          sx={{ color: "text.secondary", mr: 0 }}
        />
      </Grid>
      <Grid item textAlign="center" xs={12}>
        <Button
          disabled={!formState.isValid || formState.isSubmitting}
          endIcon={<ArrowRightAltIcon />}
          size="large"
          type="submit"
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormCallback;
