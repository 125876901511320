import { nopeResolver } from "@hookform/resolvers/nope";
import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import Nope from "nope-validator";
import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";
import { uid } from "react-uid";

import { CardBlog, CardFeatured, FormNewsletter } from "../../../components";
import { blogposts } from "../../../models";

const HeroSection = () => (
  <>
    <Typography
      align="center"
      gutterBottom
      sx={{ fontWeight: "bold" }}
      variant="h3"
    >
      Cybersecurity Insights
    </Typography>
    <Typography align="center" color="text.secondary" paragraph variant="h6">
      Keep up with the latest insights from our experts.
    </Typography>
    <Typography align="center" color="text.secondary" paragraph variant="h6">
      This space is a knowledge hub featuring security advice, deep dives into
      cyber-attacks, technical how-to&apos;s, and much more...
    </Typography>
  </>
);

const SearchForm = ({ onSubmit, register, handleSubmit, formState, data }) => (
  <Box
    component="form"
    name="filter"
    noValidate
    onSubmit={handleSubmit(onSubmit)}
  >
    <TextField
      error={Boolean(formState.errors.regex)}
      fullWidth
      helperText={
        Boolean(formState.errors.regex) && formState.errors.regex.message
      }
      id="regex"
      name="regex"
      type="text"
      {...register("regex")}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Typography
              color="text.secondary"
              sx={{ mr: "14px" }}
              variant="subtitle2"
            >
              {`${data.length} Result(s)`}
            </Typography>
            <Button
              disableElevation
              disabled={formState.isSubmitting}
              type="submit"
              variant="contained"
            >
              Search
            </Button>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="primary" />
          </InputAdornment>
        ),
      }}
      sx={{ borderRadius: 2 }}
    />
  </Box>
);

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

const BlogPostsGrid = ({ data }) => (
  <Grid
    component={motion.div}
    container
    initial={{ y: 64 }}
    spacing={2}
    transition={{
      bounce: 0.25,
      duration: 2,
      type: "spring",
    }}
    viewport={{ amount: "some", once: false }}
    whileInView={{ y: 0 }}
  >
    {data
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((item) => {
        if (!item.href) item["href"] = `/resources/blog/${item.id}`;

        return (
          <Grid item key={uid(item)} md={4} sm={6} xs={12}>
            <CardBlog
              component={motion.div}
              data={item}
              transition={{
                bounce: 0.25,
                duration: 0.5,
                type: "spring",
              }}
              whileHover={{ scale: 1.05 }}
            />
          </Grid>
        );
      })}
  </Grid>
);

BlogPostsGrid.propTypes = {
  data: PropTypes.array.isRequired,
};

const Blog = () => {
  const [data, setData] = React.useState(blogposts);

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      "form-name": "filter",
    },
    mode: "onChange",
    resolver: nopeResolver(
      Nope.object().shape({
        regex: Nope.string(),
      }),
    ),
  });

  const onSubmit = (formData) => {
    const filteredData = blogposts.filter((item) =>
      ["title", "excerpt"].some((x) =>
        item[x].toLowerCase().includes(formData.regex.toLowerCase()),
      ),
    );

    return filteredData && setData(filteredData);
  };

  return (
    <>
      <div className="outer">
        <div className="inner">
          <HeroSection />
          <SearchForm
            onSubmit={onSubmit}
            register={register}
            handleSubmit={handleSubmit}
            formState={formState}
            data={data}
          />
        </div>
      </div>
      <div className="background-primary outer">
        <div className="padding-half">
          <CardFeatured
            component={motion.div}
            data={blogposts[Math.floor(Math.random() * blogposts.length)]}
            transition={{
              bounce: 0.25,
              duration: 0.5,
              type: "spring",
            }}
            whileHover={{ scale: 1.05 }}
          />
        </div>
      </div>
      <div className="outer">
        <div className="inner">
          <BlogPostsGrid data={data} />
        </div>
      </div>
      <div className="background-default outer">
        <div className="inner">
          <FormNewsletter />
        </div>
      </div>
    </>
  );
};

export default Blog;
