import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import { Box, Button, Divider, Link, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { uid } from "react-uid";

import { FormNewsletter, HeroMasonry } from "../../../components";
import { openings } from "../../../models";
import {
  HeroCareers1,
  HeroCareers2,
  HeroCareers3,
  HeroCareers4,
} from "../../../assets/images";

const HeroSection = () => {
  const heroImages = [
    {
      gridSx: {
        alignItems: "flex-end",
        display: "flex",
        justifyContent: "flex-end",
        pl: 8,
        pt: 8,
      },
      imgSrc: HeroCareers1,
      style: {
        height: "75%",
        width: "75%",
      },
      title: "Fern",
    },
    {
      imgSrc: HeroCareers2,
      style: {
        height: "100%",
        width: "100%",
      },
      title: "Snacks",
    },
    {
      imgSrc: HeroCareers3,
      style: {
        height: "100%",
        width: "100%",
      },
      title: "Tower",
    },
    {
      gridSx: {
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "flex-start",
        pb: 8,
        pr: 8,
      },
      imgSrc: HeroCareers4,
      style: {
        height: "75%",
        width: "75%",
      },
      title: "Mushrooms",
    },
  ];

  return (
    <HeroMasonry bgImages={heroImages}>
      <Typography
        align="center"
        gutterBottom
        sx={{ fontWeight: "bolder" }}
        variant="h3"
      >
        Work With Us
      </Typography>
      <Typography align="center" color="text.secondary" paragraph variant="h6">
        Aegis Cyber are actively looking for new members to join our
        ever-growing team. Take a look at{" "}
        <Link href="#open-positions">our open positions</Link> and apply today.
      </Typography>
    </HeroMasonry>
  );
};

const JobItem = ({ item }) => (
  <Box
    key={uid(item)}
    sx={{
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <Box
      sx={{
        alignItems: { sm: "center", xs: "flex-start" },
        display: "flex",
        flex: "1 1 100%",
        flexDirection: { sm: "row", xs: "column" },
        justifyContent: "space-between",
      }}
    >
      <div>
        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
          {item.title}
        </Typography>
        <Typography color="text.secondary">{item.subheader}</Typography>
      </div>
      <div>
        <Typography color="text.secondary">{item.location}</Typography>
      </div>
    </Box>
    <div>
      <Button
        endIcon={<ArrowRightAltIcon />}
        href={item.href}
        rel="noopener noreferrer"
        sx={{ ml: 4, px: 1 }}
        target="blank"
        variant="outlined"
      >
        Apply
      </Button>
    </div>
  </Box>
);

JobItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subheader: PropTypes.string,
    location: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
};

const JobList = ({ openings }) => (
  <Stack
    divider={<Divider />}
    spacing={2}
    sx={{
      bgcolor: "background.default",
      borderColor: "divider",
      borderRadius: 2,
      borderStyle: "solid",
      borderWidth: 1,
      padding: 2,
    }}
  >
    {openings.map((item) => (
      <JobItem key={uid(item)} item={item} />
    ))}
  </Stack>
);

JobList.propTypes = {
  openings: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subheader: PropTypes.string,
      location: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const OpenPositionsSection = ({ openings }) => (
  <>
    <Typography
      align="center"
      gutterBottom
      sx={{ fontWeight: 550, textTransform: "uppercase" }}
      variant="colorPrimary"
    >
      Open Positions
    </Typography>
    <Typography align="center" gutterBottom variant="h4">
      Current Job Openings
    </Typography>
    <Typography align="center" color="text.secondary" paragraph variant="h6">
      We are always looking for talented people to join our team. If a position
      you are interested in is not advertised, please get in touch with us
      directly!
    </Typography>
    <JobList openings={openings} />
  </>
);

const Careers = () => (
  <>
    <div className="outer">
      <div className="inner">
        <HeroSection />
      </div>
    </div>
    <div className="outer">
      <div className="inner" id="open-positions">
        <OpenPositionsSection openings={openings} />
      </div>
    </div>
    <div className="background-default outer">
      <div className="inner">
        <FormNewsletter />
      </div>
    </div>
  </>
);

export default Careers;
