import { Grid } from "@mui/material";
import merge from "deepmerge";
import PropTypes from "prop-types";
import React from "react";
import { uid } from "react-uid";

const HeroComponent = ({ children, bgImages }) => (
  <>
    {children}
    <Grid container spacing={2}>
      {bgImages.map((item, index) => (
        <Grid
          item
          key={uid(item)}
          sx={item.gridSx}
          xs={[0, 3].includes(index) ? 4 : 8}
        >
          <img
            alt={item.title}
            src={item.imgSrc}
            srcSet={item.imgSrc}
            style={merge(
              {
                borderRadius: "8px",
                maxHeight: "25vh",
                objectFit: "cover",
              },
              item.style,
            )}
          />
        </Grid>
      ))}
    </Grid>
  </>
);

HeroComponent.propTypes = {
  bgImages: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default HeroComponent;
