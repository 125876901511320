import React from "react";
import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import { Button, Grid, Link, Paper, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import {
  useLoaderData,
  useNavigation,
  useOutletContext,
} from "react-router-dom";
import { uid } from "react-uid";
import slugify from "slugify";
import PropTypes from "prop-types";

import { colors } from "../../../assets";
import { CardIcon, FormQuote, LoadingSpinner } from "../../../components";

import Markdown from "react-markdown";

const components = {
  a: ({ node, ...props }) => (
    <Link target="_blank" rel="noopener noreferrer" {...props} />
  ),
  p: ({ node, ...props }) => (
    <Typography align="justify" color="text.secondary" paragraph {...props} />
  ),
};

const HeroSection = ({ loadedData, theme }) => (
  <Grid container spacing={4} alignItems="center">
    <Grid item md={6} xs={12} sx={{ "& p:last-child": { marginBottom: 0 } }}>
      <Typography gutterBottom sx={{ fontWeight: "bold" }} variant="h3">
        {loadedData.title}
      </Typography>
      <Markdown
        components={{
          p: ({ node, ...props }) => (
            <Typography
              align="justify"
              color="text.secondary"
              component="p"
              paragraph
              sx={{ fontWeight: "bold" }}
              variant="h6"
              {...props}
            />
          ),
        }}
      >
        {loadedData.description}
      </Markdown>
    </Grid>
    <Grid item md={6} xs={12}>
      <Paper
        elevation={theme.palette.mode === "dark" ? 2 : 4}
        sx={{
          borderRadius: 2,
          padding: { md: 4, xs: 2 },
        }}
      >
        <FormQuote />
      </Paper>
    </Grid>
  </Grid>
);

HeroSection.propTypes = {
  loadedData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.object.isRequired,
};

const ServiceCards = ({ loadedData, theme }) => (
  <Grid
    container
    component={motion.div}
    initial={{ y: 64 }}
    spacing={2}
    transition={{
      bounce: 0.25,
      duration: 2,
      type: "spring",
    }}
    viewport={{ amount: "some", once: false }}
    whileInView={{ y: 0 }}
  >
    {loadedData.children.map((item, index) => (
      <Grid item key={uid(item)} md={4} sm={6} xs={12}>
        <CardIcon
          avatarBackgroundColor={
            theme.palette.mode === "dark"
              ? colors.dark[index].background
              : colors.light[index].background
          }
          cardActionAreaProps={{
            href: `#${slugify(item.title, { lower: true })}`,
          }}
          component={motion.div}
          data={item}
          iconFill={
            theme.palette.mode === "dark"
              ? colors.dark[index].foreground
              : colors.light[index].foreground
          }
          transition={{
            bounce: 0.25,
            duration: 0.5,
            type: "spring",
          }}
          variant="outlined"
          whileHover={{ scale: 1.05 }}
        />
      </Grid>
    ))}
  </Grid>
);

ServiceCards.propTypes = {
  loadedData: PropTypes.shape({
    children: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.elementType.isRequired,
        title: PropTypes.string.isRequired,
        subheader: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  theme: PropTypes.object.isRequired,
};

const ServiceDetails = ({ loadedData }) =>
  loadedData.children.map((item) => (
    <div
      id={slugify(item.title, { lower: true })}
      className="inner divider-bottom"
      key={uid(item)}
    >
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontWeight: "bold" }} variant="h4">
            {item.title}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Markdown components={components}>{item.description}</Markdown>
          <Button
            disableElevation
            endIcon={<ArrowRightAltIcon />}
            href={`/contact?msg="${item.title}"`}
            size="large"
            sx={{
              bgcolor: "background.default",
              borderRadius: 2,
              color: "text.primary",
            }}
            variant="contained"
          >
            Talk to Us About This
          </Button>
        </Grid>
      </Grid>
    </div>
  ));

ServiceDetails.propTypes = {
  loadedData: PropTypes.shape({
    children: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

const ServicesView = () => {
  const theme = useTheme();
  const outletContext = useOutletContext();
  const loadedData = useLoaderData();
  const { state = "loading" } = useNavigation();

  if (state === "loading") return <LoadingSpinner sx={outletContext.sx} />;

  return (
    <>
      <div className="outer">
        <div className="inner">
          <HeroSection loadedData={loadedData} theme={theme} />
        </div>
      </div>
      <div className="outer">
        <div className="divider-bottom inner">
          <ServiceCards loadedData={loadedData} theme={theme} />
        </div>
      </div>
      <div className="outer">
        <ServiceDetails loadedData={loadedData} />
      </div>
    </>
  );
};

export default ServicesView;
