import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  SvgIcon,
  Typography,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { uid } from "react-uid";

import { Divider as SvgDivider } from "../../assets/optimized-svgs";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const CardFeatured = ({ data, ...props }) => (
  <Card
    {...props}
    sx={{
      borderRadius: 2,
      height: "100%",
    }}
    variant="outlined"
  >
    <CardActionArea
      href={data.href}
      {...(data.href.startsWith("//") && {
        component: "a",
        rel: "noopener noreferrer",
        target: "_blank",
      })}
      sx={{
        alignItems: "stretch",
        display: "flex",
        flexDirection: {
          md: "row-reverse",
          xs: "column",
        },
        height: "100%",
      }}
    >
      <CardMedia
        alt={data.imgAlt}
        sx={{
          backgroundImage: `url(${data.imgSrc})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: 320,
          position: "relative",
          width: { md: "50%", xs: "100%" },
        }}
        variant="filter"
      >
        {/* TODO: Adapt divider on XS screen */}
        <SvgIcon
          component={SvgDivider}
          id="background-default"
          inheritViewBox
          sx={{
            bottom: 0,
            height: { md: "100%", xs: 32 },
            left: 0,
            position: "absolute",
            transform: { md: "rotate(180deg)", xs: "none" },
            width: { md: 64, xs: "100%" },
            zIndex: 0,
          }}
        />
        <Box
          component="ul"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none",
            margin: 0,
            padding: 0,
            position: "absolute",
            right: 12,
            top: 12,
          }}
        >
          {data.categories.map((category) => (
            <ListItem key={uid(category)}>
              <Chip
                label={category}
                sx={{
                  bgcolor: "background.default",
                  fontWeight: 550,
                }}
                variant="outlined"
              />
            </ListItem>
          ))}
        </Box>
      </CardMedia>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <CardContent
          sx={{
            flexGrow: 1,
          }}
        >
          <Typography gutterBottom variant="h6">
            {data.title}
          </Typography>
          <Typography color="text.secondary" variant="subtitle1">
            {data.excerpt}
          </Typography>
        </CardContent>
        <CardActions
          sx={(theme) => ({
            borderTop: `1px solid ${theme.palette.divider}`,
            justifyContent: "space-between",
            padding: 2,
          })}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Avatar
              alt={data.author.name}
              src={data.author.imgSrc}
              sx={{ mr: 1 }}
            />
            <Typography color="text.secondary">{data.author.name}</Typography>
          </Box>
          <Typography color="text.secondary">{data.date}</Typography>
        </CardActions>
      </Box>
    </CardActionArea>
  </Card>
);

CardFeatured.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CardFeatured;
