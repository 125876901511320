import { nopeResolver } from "@hookform/resolvers/nope";
import { Button, Grid, TextField, Typography } from "@mui/material";
import Nope from "nope-validator";
import { useSnackbar } from "notistack";
import React from "react";
import ReactGA from "react-ga4";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

const FormNewsletter = () => {
  const { pathname } = useLocation();

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      "form-name": "newsletter",
      page: pathname,
    },
    mode: "onChange",
    resolver: nopeResolver(
      Nope.object().shape({
        email: Nope.string().email().required(),
      }),
    ),
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formData) => {
    ReactGA.event({
      action: "submit",
      category: "form",
      label: "newsletter",
    });

    // TODO: Required to bypass the SW cache for Netlify form compatibility
    fetch("/?t=" + Math.floor(Date.now() / 1000), {
      body: new URLSearchParams(formData).toString(),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
    })
      .then(() =>
        enqueueSnackbar("Form successfully submitted!", {
          variant: "success",
        }),
      )
      .catch(() =>
        enqueueSnackbar(
          "Something went wrong and your message could not be sent.",
          {
            variant: "error",
          },
        ),
      );
  };

  return (
    <Grid
      component="form"
      container
      justifyContent="center"
      name="newsletter"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
    >
      <Grid item md={6} xs={12}>
        <Typography sx={{ fontWeight: "bold" }} variant="h4">
          Join our 1,000+ subscribers and stay informed with our newsletter!
        </Typography>
      </Grid>
      <Grid
        item
        md={6}
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: { sm: "row", xs: "column" },
        }}
        xs={12}
      >
        <TextField
          {...register("email")}
          autoComplete="email"
          error={Boolean(formState.errors.email)}
          fullWidth
          helperText={
            Boolean(formState.errors.email) && formState.errors.email.message
          }
          id="email"
          label="Enter your email address"
          name="email"
          required
          type="email"
        />
        <Button
          disableElevation
          disabled={!formState.isValid || formState.isSubmitting}
          size="large"
          sx={{
            height: 56,
            ml: { sm: 2, xs: 0 },
            mt: { sm: 0, xs: 2 },
            width: { sm: "auto", xs: "100%" },
          }}
          type="submit"
          variant="contained"
        >
          Subscribe
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormNewsletter;
