import { Button, Snackbar } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

const CookieConsent = ({ anchorOrigin, buttonLabel, message, ...props }) => {
  const [open, setOpen] = React.useState();

  useEffect(() => {
    const hasConsent = JSON.parse(localStorage.getItem("hasConsent")) || false;
    setOpen(!hasConsent);
  }, []);

  return (
    <Snackbar
      {...props}
      ContentProps={{
        sx: {
          borderRadius: 2,
          padding: { md: 4, sm: 3, xs: 2 },
        },
      }}
      action={
        <Button
          disableElevation
          onClick={() => {
            localStorage.setItem("hasConsent", true);
            setOpen(false);
          }}
          variant="contained"
        >
          {buttonLabel}
        </Button>
      }
      anchorOrigin={anchorOrigin}
      message={message}
      open={open}
    />
  );
};

CookieConsent.propTypes = {
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(["left", "center", "right"]).isRequired,
    vertical: PropTypes.oneOf(["top", "bottom"]).isRequired,
  }).isRequired,
  buttonLabel: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
};

export default CookieConsent;
