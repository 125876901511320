import { Avatar, Card, CardActionArea, CardHeader } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CardIcon = ({
  avatarBackgroundColor,
  data,
  iconFill,
  cardActionAreaProps,
  ...props
}) => (
  <Card
    {...props}
    sx={(theme) =>
      Object.assign(
        {
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        },
        data.title.includes("Penetration Testing") && {
          border: `3px solid ${theme.palette.primary.main}`,
          transform: "scale(1.05)",
        },
      )
    }
  >
    <CardActionArea {...cardActionAreaProps} sx={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{
              bgcolor: avatarBackgroundColor,
              height: { md: 64, sm: 56, xs: 48 },
              width: { md: 64, sm: 56, xs: 48 },
            }}
          >
            <data.icon
              sx={{
                fill: iconFill,
                fontSize: { md: 32, sm: 28, xs: 24 },
              }}
            />
          </Avatar>
        }
        subheader={data.subheader}
        subheaderTypographyProps={{
          align: "justify",
          variant: "subtitle1",
        }}
        sx={{
          alignItems: "flex-start",
          flexDirection: "column",
        }}
        title={data.title}
        titleTypographyProps={{
          gutterBottom: true,
          variant: "h6",
        }}
      />
    </CardActionArea>
  </Card>
);

CardIcon.propTypes = {
  avatarBackgroundColor: PropTypes.string,
  cardActionAreaProps: PropTypes.object.isRequired,
  data: PropTypes.shape({
    icon: PropTypes.elementType.isRequired,
    title: PropTypes.string.isRequired,
    subheader: PropTypes.string,
  }).isRequired,
  iconFill: PropTypes.string,
};

export default CardIcon;
