import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const LoadingSpinner = ({ sx }) => (
  <Box
    sx={{
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      ...sx,
    }}
  >
    <CircularProgress />
    <Typography variant="subtitle1">Loading...</Typography>
  </Box>
);

LoadingSpinner.propTypes = {
  sx: PropTypes.object,
};

export default LoadingSpinner;
