import {
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React from "react";

const DarkModeButton = ({ darkMode, onToggleDarkMode }) => {
  const title = darkMode ? "Switch to light mode" : "Switch to dark mode";

  return (
    <IconButton
      color="inherit"
      title={title}
      size="small"
      onClick={onToggleDarkMode}
      sx={{
        borderColor: "divider",
        borderRadius: 2,
        borderStyle: "solid",
        borderWidth: 1,
      }}
    >
      {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
};

DarkModeButton.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  onToggleDarkMode: PropTypes.func.isRequired,
};

export default DarkModeButton;
