import React from "react";
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  SvgIcon,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  useLoaderData,
  useNavigation,
  useOutletContext,
} from "react-router-dom";
import Markdown from "react-markdown";

import { Divider as SvgDivider } from "../../assets/optimized-svgs";

const components = {
  h1: ({ node, ...props }) => (
    <Typography component="p" variant="h6" gutterBottom {...props} />
  ),
  p: ({ node, ...props }) => (
    <Typography align="justify" color="text.secondary" paragraph {...props} />
  ),
  em: ({ node, ...props }) => (
    <Typography component="i" style={{ color: "red" }} {...props} />
  ),
  a: ({ node, ...props }) => <Link {...props} />,
  ol: ({ node, ...props }) => (
    <List
      component="ol"
      disablePadding
      sx={{
        color: "text.secondary",
        listStyleType: "lower-alpha",
        ml: 4,
        mb: 2,
      }}
      {...props}
    />
  ),
  li: ({ node, ...props }) => (
    <ListItem disablePadding sx={{ display: "list-item" }}>
      <ListItemText sx={{ color: "text.secondary" }} primary={props.children} />
    </ListItem>
  ),
};

const SideBox = ({ data, outletContext }) => (
  <Paper
    sx={{
      borderRadius: 2,
      overflow: "hidden",
      padding: { md: 4, xs: 3 },
      position: "sticky",
      textOverflow: "ellipsis",
      top: `calc(${outletContext.appBarHeight}px + 16px)`,
    }}
    variant="outlined"
  >
    <Typography gutterBottom>
      How can you contact us about this notice?
    </Typography>
    <Typography color="text.secondary" gutterBottom variant="body2">
      If you have any questions or concerns about our {data.notice}, please
      contact us at:
    </Typography>
    <Link
      component="a"
      href="mailto:support@aegiscyber.co.uk"
      variant="subtitle2"
    >
      support@aegiscyber.co.uk
    </Link>
  </Paper>
);

SideBox.propTypes = {
  data: PropTypes.object.isRequired,
};

const LegalView = () => {
  const outletContext = useOutletContext();
  const loadedData = useLoaderData();
  const { state = "loading" } = useNavigation();

  const renderNoticeHeader = () => (
    <Box
      className="background-gradient"
      sx={{ padding: { md: "64px 32px", xs: "32px 16px" } }}
    >
      <Typography gutterBottom variant="h3" sx={{ fontWeight: "bolder" }}>
        {loadedData.notice}
      </Typography>
      <Typography>
        Last modified on <strong>{loadedData.lastEdit}</strong>.
      </Typography>
    </Box>
  );

  const renderMainContent = () => (
    <Grid
      container
      direction="row-reverse"
      spacing={{ md: 4, xs: 2 }}
      sx={{ padding: { md: 4, xs: 3 } }}
    >
      <Grid item md={3} xs={12}>
        <SideBox data={loadedData} outletContext={outletContext} />
      </Grid>
      <Grid item md={9} xs={12} sx={{ "& p:last-child": { marginBottom: 0 } }}>
        <Markdown components={components}>{loadedData.background}</Markdown>
        <Markdown components={components}>{loadedData.questions}</Markdown>
      </Grid>
    </Grid>
  );

  if (state === "loading") return <LoadingSpinner sx={outletContext.sx} />;

  return (
    <div className="outer">
      <div className="inner">
        <Paper elevation={4} sx={{ borderRadius: 2 }} variant="elevation">
          <div className="divider-svg">
            {renderNoticeHeader()}
            <SvgIcon
              component={SvgDivider}
              id="elevation-4"
              inheritViewBox
              sx={{
                bottom: 0,
                height: 128,
                left: 0,
                position: "absolute",
                width: "100%",
                zIndex: 0,
              }}
            />
          </div>
          {renderMainContent()}
        </Paper>
      </div>
    </div>
  );
};

export default LegalView;
