import { typographyClasses, alpha } from "@mui/material";
import { red } from "@mui/material/colors";
import merge from "deepmerge";
import PropTypes from "prop-types";
import React from "react";
import { NavLink as RouterLink } from "react-router-dom";

const LinkBehavior = React.forwardRef(({ href, end, ...props }, ref) => (
  <RouterLink
    data-testid="custom-link"
    end={end}
    ref={ref}
    to={href}
    {...props}
  />
));

LinkBehavior.propTypes = {
  end: PropTypes.bool,
  href: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

const colors = {
  background: {
    dark: {
      darker: "#080808",
      // default: '#121212',
      // paper: '#161616',
    },
    light: {
      darker: "#f7faff",
      // default: '#ffffff',
      // paper: '#f7faff',
    },
  },
  primary: {
    dark: red[700],
    light: red[400],
  },
};

export const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "dark"
      ? {
          background: {
            darker: colors.background.dark.darker,
            // default: colors.background.dark.default,
            // paper: colors.background.dark.paper,
          },
          primary: { main: colors.primary.dark },
        }
      : {
          background: {
            darker: colors.background.light.darker,
            // default: colors.background.light.default,
            // paper: colors.background.light.paper,
          },
          primary: { main: colors.primary.light },
        }),
    // Used by `getContrastText()` to maximize the contrast between
    // The background and the text.
    contrastThreshold: 4,
  },
});

const linkBehaviorOverride = {
  defaultProps: {
    component: LinkBehavior,
  },
};

// TODO: Construct styles based on theme values
export const getThemedComponents = (mode) => ({
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor:
            mode === "dark" ? colors.primary.dark : colors.primary.light,
        },
      },
      variants: [
        {
          props: { variant: "filter" },
          style: {
            ...(mode === "dark" && {
              filter: "grayscale(0.25)",
            }),
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor:
            mode === "dark" ? colors.primary.dark : colors.primary.light,
        },
        containedSizeLarge: {
          fontWeight: "bolder",
        },
        containedSizeMedium: {
          fontWeight: "bold",
        },
        outlinedPrimary: {
          borderColor:
            mode === "dark" ? colors.primary.dark : colors.primary.light,
          color: mode === "dark" ? colors.primary.light : colors.primary.dark,
        },
        textPrimary: {
          color: mode === "dark" ? colors.primary.light : colors.primary.dark,
          fontWeight: "bold",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [`:not([id^='logo']).active`]: {
            backgroundColor: `${alpha(
              mode === "dark" ? colors.primary.dark : colors.primary.light,
              0.16, // <- theme.palette.action.selectedOpacity
            )} !important`,
          },
        },
      },
    },
    MuiCardMedia: {
      variants: [
        {
          props: { variant: "filter" },
          style: {
            // XXX: Counter the brightness filter for the SDVG divider
            "& svg": {
              filter: mode === "dark" && "brightness(1.25)",
            },

            filter: mode === "dark" && "brightness(0.75)",
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "filter" },
          style: {
            ...(mode === "dark" && {
              filter: "grayscale(0.25)",
            }),
          },
        },
      ],
    },
    // XXX: Track https://github.com/mui-org/material-ui/issues/28354
    MuiLink: merge(linkBehaviorOverride, {
      styleOverrides: {
        root: {
          color: mode === "dark" ? colors.primary.light : colors.primary.dark,
          fontWeight: 550,
        },
      },
    }),
    // XXX: Track https://github.com/mui-org/material-ui/issues/28354
    // MuiListItemButton: linkBehaviorOverride,
    MuiMenuItem: linkBehaviorOverride,
    MuiSvgIcon: {
      styleOverrides: {
        // XXX: Set class="colorPrimary" to light/dark
        // Aware svg paths
        ".colorPrimary": {
          fill: `${
            mode === "dark" ? colors.primary.dark : colors.primary.light
          } !important`,
        },
      },
      variants: [
        {
          props: { variant: "filter" },
          style: {
            ...(mode === "dark" && {
              filter: "grayscale(0.25)",
            }),
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: "p",
          body2: "p",
          h1: "h1",
          h2: "h1",
          h3: "h2",
          h4: "h3",
          h5: "h5",
          h6: "span",
          subtitle1: "p", // Default: h6
          subtitle2: "p", // Default: h6
        },
      },
      styleOverrides: {
        // HACK: This is a temporary fix waiting for a
        // Better solution
        h4: {
          [`&.${typographyClasses.alignCenter}`]: {
            width: "100%",
          },

          [`&.${typographyClasses.gutterBottom}`]: {
            ":after": {
              background: `none repeat scroll 0 0 ${
                mode === "dark" ? colors.primary.dark : colors.primary.light
              }`,
              bottom: -8,
              content: '""',
              display: "block",
              height: 3,
              left: "50%",
              position: "absolute",
              transform: "translate(-50%)",
              width: 96,
            },

            display: "inline-block",
            marginBottom: 32,
            position: "relative",
          },

          fontWeight: "bold",
        },
      },
      variants: [
        {
          props: { variant: "colorPrimary" },
          style: {
            color: mode === "dark" ? colors.primary.light : colors.primary.dark,
            display: "block",
          },
        },
        // NOTE: Keep an eye on https://github.com/mui/material-ui/issues/31147
        // {
        //   Props: { variant: 'tag' },
        //   Style: {
        //     FontWeight: 550,
        //     TextTransform: 'uppercase',
        //   },
        // },
      ],
    },
  },
});
