import React from "react";
import { Box, Grid, Link, SvgIcon, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import Slider from "react-slick";
import { uid } from "react-uid";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { colors } from "../../assets";
import { certificationBodies, usps } from "../../models";
import { CardTextual, HeroMasonry } from "../../components";
import {
  HeroAbout1,
  HeroAbout2,
  HeroAbout3,
  HeroAbout4,
} from "../../assets/images";

const HeroSection = () => {
  const heroImages = [
    {
      gridSx: {
        alignItems: "flex-end",
        display: "flex",
        justifyContent: "flex-end",
        pl: 8,
        pt: 8,
      },
      imgSrc: HeroAbout1,
      style: {
        height: "75%",
        width: "75%",
      },
      title: "Fern",
    },
    {
      imgSrc: HeroAbout2,
      style: {
        height: "100%",
        width: "100%",
      },
      title: "Snacks",
    },
    {
      imgSrc: HeroAbout3,
      style: {
        height: "100%",
        width: "100%",
      },
      title: "Tower",
    },
    {
      gridSx: {
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "flex-start",
        pb: 8,
        pr: 8,
      },
      imgSrc: HeroAbout4,
      style: {
        height: "75%",
        width: "75%",
      },
      title: "Mushrooms",
    },
  ];

  return (
    <HeroMasonry bgImages={heroImages}>
      <Typography
        align="center"
        gutterBottom
        sx={{ fontWeight: "bolder" }}
        variant="h3"
      >
        About Aegis Cyber
      </Typography>
      <Typography align="center" color="text.secondary" paragraph variant="h6">
        Aegis Cyber is a UK-based consultancy firm founded in 2019 with the aim
        to provide outstanding services to businesses around the world at
        competitive prices.
      </Typography>
      <Typography align="center" color="text.secondary" paragraph variant="h6">
        Our team of <Link href="#certifications">certified experts</Link> -
        among the best in the industry - bring decades worth of professional
        experience to help you protect and secure your digital assets.
      </Typography>
    </HeroMasonry>
  );
};

const AboutSection = () => (
  <Grid alignItems="flex-start" container spacing={2}>
    <Grid item md={6} xs={12}>
      <Typography sx={{ fontWeight: "bold" }} variant="h4">
        We discover weaknesses within your organisation before the bad guys do…
      </Typography>
    </Grid>
    <Grid item md={6} xs={12}>
      <Typography
        align="justify"
        className="text-secondary-on-primary-background"
        paragraph
      >
        We leverage our{" "}
        <Link
          sx={{
            color: "text.primary",
            textDecoration: "underline",
          }}
          href="#strengths"
        >
          strengths
        </Link>{" "}
        and genuine drive for Cyber Security to help businesses of all size
        uncovering vulnerabilities within their products, infrastructure, and in
        the wide sense any business-critical digital assets.
      </Typography>
      <Typography
        align="justify"
        className="text-secondary-on-primary-background"
        paragraph
      >
        Having worked with organisations, companies, and corporations in both
        the public and private sectors, our specialists have a broad range of
        skills that enable us to provide the most comprehensive, yet affordable
        approach to cyber security.
      </Typography>
      <Typography
        align="justify"
        className="text-secondary-on-primary-background"
      >
        Our aim to not just meet the expectations of every client we work with
        but to exceed them.
      </Typography>
    </Grid>
  </Grid>
);

const StrengthsSection = ({ theme }) => (
  <>
    <Typography
      align="center"
      gutterBottom
      sx={{ fontWeight: 550, textTransform: "uppercase" }}
      variant="colorPrimary"
    >
      Strengths
    </Typography>
    <Typography align="center" gutterBottom variant="h4">
      What Sets us Apart
    </Typography>
    <Grid
      component={motion.div}
      container
      initial={{ y: 64 }}
      spacing={2}
      transition={{
        bounce: 0.25,
        duration: 2,
        type: "spring",
      }}
      viewport={{ amount: "some", once: false }}
      whileInView={{ y: 0 }}
    >
      {usps.map((data, index) => (
        <Grid item key={uid(data)} sm={6} xs={12}>
          <CardTextual
            avatarBackgroundColor={
              theme.palette.mode === "dark"
                ? colors.dark[index].background
                : colors.light[index].background
            }
            data={data}
            hasIcon
            iconFill={
              theme.palette.mode === "dark"
                ? colors.dark[index].foreground
                : colors.light[index].foreground
            }
          />
        </Grid>
      ))}
    </Grid>
  </>
);

StrengthsSection.propTypes = {
  theme: PropTypes.object.isRequired,
};

const CertificationSlider = ({ theme }) => {
  const sliderSettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Typography
        align="center"
        gutterBottom
        sx={{ fontWeight: 550, textTransform: "uppercase" }}
        variant="colorPrimary"
      >
        Certified
      </Typography>
      <Typography align="center" gutterBottom variant="h4">
        Our Experts&apos; Accreditations
      </Typography>
      <Box sx={{ paddingX: 2 }}>
        <Slider {...sliderSettings}>
          {certificationBodies.map((item) => (
            <Box
              key={uid(item)}
              sx={{
                height: {
                  md: 128,
                  sm: 112,
                  xs: 96,
                },
              }}
            >
              <SvgIcon
                component={item.svg}
                inheritViewBox
                sx={(theme) => ({
                  '&[id^="offsec"]': {
                    path: {
                      fill:
                        theme.palette.mode === "light" &&
                        "rgba(0, 0, 0, 0.75) !important",
                    },
                  },
                  height: "100%",
                  width: "100%",
                })}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </>
  );
};

CertificationSlider.propTypes = {
  theme: PropTypes.object.isRequired,
};

const AboutView = () => {
  const theme = useTheme();

  return (
    <>
      <div className="outer">
        <div className="inner">
          <HeroSection />
        </div>
      </div>
      <div className="background-primary outer">
        <div className="inner">
          <AboutSection />
        </div>
      </div>
      <div className="outer">
        <div className="inner" id="strengths">
          <StrengthsSection theme={theme} />
        </div>
        <div className="inner" id="certifications">
          <CertificationSlider theme={theme} />
        </div>
      </div>
    </>
  );
};

export default AboutView;
