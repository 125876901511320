import React from "react";
import { IconButton, Stack } from "@mui/material";
import { uid } from "react-uid";
import PropTypes from "prop-types";

const SocialMediaLinks = ({ socialMedias }) => (
  <Stack direction="row">
    {socialMedias.map((item) => (
      <IconButton
        key={uid(item)}
        aria-label={item.ariaLabel}
        color="inherit"
        component="a"
        href={item.href}
        rel="noopener noreferrer"
        target="_blank"
        size="small"
      >
        <item.icon />
      </IconButton>
    ))}
  </Stack>
);

SocialMediaLinks.propTypes = {
  socialMedias: PropTypes.arrayOf(
    PropTypes.shape({
      ariaLabel: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
};

export default SocialMediaLinks;
