import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Avatar,
  Box,
  Chip,
  Divider,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { uid } from "react-uid";

import { NotFound } from "../../../assets/optimized-svgs";
import { CardBlog, FormNewsletter, Hero } from "../../../components";
import { blogposts } from "../../../models";
import { IconView } from "..";
import Markdown from "react-markdown";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const Author = ({ data, textColor }) => (
  <Box
    sx={{
      alignItems: "center",
      display: "flex",
    }}
  >
    <Avatar
      alt={data.author.name}
      src={data.author.imgSrc}
      sx={{ height: 56, mr: 2, width: 56 }}
    />
    <div>
      <Typography
        color={textColor ? textColor.name : "#FFFFFFFF"}
        sx={{
          display: "block",
        }}
        variant="h6"
      >
        {data.author.name}
      </Typography>
      <Typography
        color={textColor ? textColor.date : "#FFFFFFB3"}
        sx={{
          display: "block",
        }}
        variant="subtitile2"
      >
        {data.date}
      </Typography>
    </div>
  </Box>
);

Author.propTypes = {
  data: PropTypes.object.isRequired,
  textColor: PropTypes.object,
};

const DiscoverMore = ({ randomPosts }) => {
  const theme = useTheme();

  const sliderSettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 1,
    slidesToShow: 3,
    speed: 500,
  };

  return (
    <Box sx={{ paddingX: 2 }}>
      <Slider {...sliderSettings}>
        {[...Array(3)].map((n, index) => {
          randomPosts[index]["href"] =
            `/resources/blog/${randomPosts[index].id}`;

          return (
            <Box
              key={uid(index)}
              sx={{
                padding: 2,
              }}
            >
              <CardBlog
                component={motion.div}
                data={randomPosts[index]}
                transition={{
                  bounce: 0.25,
                  duration: 0.5,
                  type: "spring",
                }}
                whileHover={{ scale: 1.05 }}
              />
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};

DiscoverMore.propTypes = {
  randomPosts: PropTypes.array.isRequired,
};

const SkeletonPost = () => {
  const { id } = useParams();
  const filteredData = blogposts.filter((x) => x.id === parseInt(id))[0];

  // We remove the current blog post from
  // Blogposts to avoid displaying it in the suggestions
  const filteredBlogposts = blogposts.filter((x) => x !== blogposts[id]);
  const randomPosts = filteredBlogposts
    .sort(() => Math.random() - Math.random())
    .slice(0, 3);

  return filteredData ? (
    <>
      <Hero
        bgImages={{
          lg: "/landing/hero_xl.webp",
          md: "/landing/hero_lg.webp",
          sm: "/landing/hero_md.webp",
          xl: "/landing/hero_xl.webp",
          xs: "/landing/hero_sm.webp",
        }}
      >
        <Box
          component="ul"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            listStyle: "none",
            margin: "0 0 16px 0",
            padding: 0,
          }}
        >
          {filteredData.categories.map((item) => (
            <ListItem key={uid(item)}>
              <Chip
                label={item}
                sx={{
                  bgcolor: "background.default",
                  fontWeight: 550,
                }}
                variant="outlined"
              />
            </ListItem>
          ))}
        </Box>
        <Typography color="#FFFFFFFF" gutterBottom variant="h3">
          {filteredData.title}
        </Typography>
        <Author data={filteredData} />
      </Hero>
      <div className="outer">
        <div className="inner">
          <Markdown
            components={{
              p: ({ node, ...props }) => (
                <Typography align="justify" color="text.secondary" {...props} />
              ),
            }}
          >
            {filteredData.content}
          </Markdown>
          <Divider sx={{ marginY: 2 }} />
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Author
              data={filteredData}
              textColor={{
                date: "text.secondary",
                name: "text.primary",
              }}
            />
          </Box>
        </div>
      </div>
      <div className="background-primary outer">
        <div className="inner">
          {/* HACK: sx workaround for the red underlined variant */}
          <Typography sx={{ mb: 1 }} variant="h4">
            Extend Your Research
          </Typography>
          <Typography
            className="text-secondary-on-primary-background"
            paragraph
          >
            Here are some more readings that you may find insightful.
          </Typography>
          <DiscoverMore randomPosts={randomPosts} />
        </div>
      </div>
      <div className="background-default outer">
        <div className="inner">
          <FormNewsletter />
        </div>
      </div>
    </>
  ) : (
    <IconView
      icon={NotFound}
      message="It seems that the page you were trying to reach does not exist anymore, or maybe it has just moved."
      title="Page Not Found"
    />
  );
};

export default SkeletonPost;
