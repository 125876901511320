import { Star as StarIcon } from "@mui/icons-material";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { yellow } from "@mui/material/colors";
import PropTypes from "prop-types";
import React from "react";
import { uid } from "react-uid";

const CardTestimonial = ({ data, index, ...props }) => (
  <Card
    {...props}
    sx={Object.assign(
      {
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      },
      index === 1 && {
        backgroundColor: "primary.main",
        transform: "scale(1.05)",
      },
    )}
    variant="outlined"
  >
    <CardContent>
      <div>
        {[...Array(5)].map((e, i) => (
          <StarIcon
            key={uid(i)}
            sx={(theme) => ({
              color: theme.palette.mode === "dark" ? yellow[400] : yellow[700],
            })}
          />
        ))}
      </div>
      <Typography
        {...(index === 1 && {
          className: "text-secondary-on-primary-background",
        })}
        align="justify"
        color="text.secondary"
      >
        {data.content}
      </Typography>
    </CardContent>
    <CardActions sx={{ padding: 2 }}>
      <div>
        <Typography>{data.name}</Typography>
        <Typography variant="body2">
          {data.role} at <strong>{data.company}</strong>
        </Typography>
      </div>
    </CardActions>
  </Card>
);

CardTestimonial.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default CardTestimonial;
