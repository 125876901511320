import { ArrowRightAlt as ArrowRightAltIcon } from "@mui/icons-material";
import { Box, Button, SvgIcon, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { Divider } from "../../assets/optimized-svgs";

const BoxHero = styled(Box)(() => ({
  backgroundAttachment: "fixed",
  backgroundBlendMode: "overlay",
  backgroundColor: "rgb(0 0 0 / 0.75)",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative",
}));

const HeroComponent = ({ children, CTA, bgImages, ...props }) => (
  <BoxHero
    className="background-default"
    component="div"
    sx={{
      backgroundImage: {
        lg: `url(${bgImages.lg})`,
        md: `url(${bgImages.md})`,
        sm: `url(${bgImages.sm})`,
        xl: `url(${bgImages.xl})`,
        xs: `url(${bgImages.xs})`,
      },
    }}
    {...props}
  >
    <div className="front padding-hero">
      <div>{children}</div>
      {CTA && (
        <Box
          sx={{
            mt: 4,
            textAlign: "center",
          }}
        >
          <Button
            disableElevation
            endIcon={<ArrowRightAltIcon />}
            href={CTA.href}
            size="large"
            sx={{
              width: { sm: "auto", xs: "100%" },
            }}
            variant="contained"
          >
            {CTA.text}
          </Button>
        </Box>
      )}
    </div>
    <SvgIcon
      component={Divider}
      id="background-default-svg"
      inheritViewBox
      sx={{
        bottom: 0,
        height: 128,
        left: 0,
        position: "absolute",
        width: "100%",
        zIndex: 0,
      }}
    />
  </BoxHero>
);

HeroComponent.propTypes = {
  CTA: PropTypes.object,
  bgImages: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default HeroComponent;
